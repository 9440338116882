/* --- SLIDER --- */
jQuery(document).ready(function(){
    jQuery('.slider').slick({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        centerPadding: '40px',
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    });

    jQuery('.reviews-container').slick({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      centerPadding: '40px',
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
  });

  jQuery('.numbers .cards').slick({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: '40px',
    mobileFirst: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      // {
      //   breakpoint: 1200,
      //   settings: "unslick"
      // },
      // {
      //   breakpoint: 1199,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 1
      //   }
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: "unslick"
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
});

});
jQuery(window).resize(function () {
  jQuery('.numbers .cards').not('.slick-initialized').slick('resize');
});

jQuery(window).on('orientationchange', function () {
  jQuery('.numbers .cards').not('.slick-initialized').slick('resize');
});