jQuery(document).ready(function() {
    // MOBILE MENU EXPANDING/COLLAPSING 
    let hamburger = document.getElementById('hamburger');
    let mobileMenu = document.getElementById('mobile-menu');
    
    
    hamburger.onclick = function() {
        // if we are closing the menu
        if (this.classList.contains('is-active')) {
            this.classList.remove('is-active');
            mobileMenu.classList.remove('mobile-menu-open');
            let html = document.getElementsByTagName('html')[0];
            html.style.overflowX = 'hidden';
            html.style.overflowY = 'visible';
        } 
        // otherwise we are opening the menu
        else {
            console.log(html);
            this.classList.add('is-active');
            mobileMenu.classList.add('mobile-menu-open');
            let html = document.getElementsByTagName('html')[0];
            html.style.overflowX = 'hidden';
            html.style.overflowY = 'hidden';
        }
    }
    // close mobile menu if clicking off menu
    document.addEventListener('click', function(e) { 
        // if (!e.path.includes(hamburger) && !e.path.includes(mobileMenu) && hamburger.classList.contains('is-active')) {
        //     hamburger.classList.remove('is-active');
        //     mobileMenu.classList.remove('mobile-menu-open');
        //     document.body.style.overflow = 'auto';
        // }
    });

    // close mobile menu if clicking link in nav
    let links = document.querySelectorAll('.mobile-menu a');
    for (let i=0; i<links.length; i++) {
        let link = links[i];
        link.addEventListener('click', function() {
            hamburger.classList.remove('is-active');
            mobileMenu.classList.remove('mobile-menu-open');
            let html = document.getElementsByTagName('html')[0];
            html.style.overflowX = 'hidden';
            html.style.overflowY = 'visible';
        })
    }


    // Expand sub menus on the mobile menu
    let buttons = document.querySelectorAll('.mobile-menu .menu-item-has-children b');
    buttons.forEach((button) => {
        button.addEventListener('click', function(e) {
            e.preventDefault();
            let button = this;
            let subMenu = this.parentNode.nextElementSibling;

            if (subMenu.classList.contains('open')) {
                subMenu.classList.remove('open');
                subMenu.style.height = '0px';

                button.style.transform = 'translateY(-50%)';
            } else {
                subMenu.classList.add('open');
                subMenu.style.height = subMenu.scrollHeight + 'px';

                button.style.transform = 'rotateX(180deg) translateY(50%)';
            }
        })
    });



    // Open search menu with search button
    // let searchButton = document.querySelectorAll('.header .search-button')[0];
    // let searchBar = document.querySelectorAll('.header .search-bar')[0];
    // searchButton.addEventListener('click', function() {

    //     if (searchBar.classList.contains('search-bar-visible')) {
    //         searchBar.classList.remove('search-bar-visible');
    //         searchButton.classList.remove('search-button-visible');
    //     } else {
    //         searchBar.classList.add('search-bar-visible');     
    //         searchButton.classList.add('search-button-visible');
    //         let searchInput = document.querySelectorAll('.search-form .search-field')[0]
    //         searchInput.focus();
    //         searchInput.setSelectionRange(1000, 1000);
    //     }
        
    // });
    // // close search if clicking off mouse
    // document.addEventListener('click', function(e) { 
    //     if (!e.path.includes(searchBar) && !e.path.includes(searchButton)) {
    //         searchBar.classList.remove('search-bar-visible');
    //         searchButton.classList.remove('search-button-visible');
    //     }
    // });



    // video popup handling
    function closeVideo(el) {
        jQuery(el).closest('.video-popup-container').fadeOut();
        jQuery('.video-popup-inner').each(function(){
            let iframe = this.querySelector('iframe');
            iframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        });
    }

    jQuery('.close-popup').click(function() {
        closeVideo(this);
    })
    // also close popup if clicking off video
    jQuery('.video-popup-inner').click(function() {
        closeVideo(this);
    })

    jQuery('.open-popup').click(function() {
        jQuery(this).parent().next().fadeIn();
        console.log(jQuery(this).closest('.video-popup-container'));
        jQuery('.video-popup-inner iframe').each(function(){
            if (!this.src.includes('/?enablejsapi=1')) {
                this.src += '/?enablejsapi=1';
            }
        });
    })

});

   
